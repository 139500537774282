import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Smallstep Certificate Manager is a commercial product built on `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` that delivers a highly available hosted certificate authorities, expiry notifications and alerts, a management dashboard, Active Revocation and other features. With Smallstep Certificate Manager, you can easily issue private TLS/SSL certificates to all your things. `}<a parentName="p" {...{
        "href": "https://smallstep.com/certificate-manager"
      }}>{`Learn more here.`}</a></p>
    <Alert severity="info" mb={4} mdxType="Alert">
  <div>
    Are you looking for the open source <Code mdxType="Code">step-ca</Code> documentation? <Link href="/docs/step-ca" mdxType="Link">Find it here</Link>.
  </div>
    </Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      